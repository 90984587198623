/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider, ReactReduxContext } from "react-redux";

import { AnalyticsProvider } from "context/analytics";
import { ScreenOrientationProvider } from "context/browser";

import { appHistory, appStore, withSentry } from "./config";
import { usePlayerLoader } from "./hooks/usePlayerLoader";
import { RootScreen } from "./screens";

console.log(
  `%cVERSION: %c${process.env.REACT_APP_VERSION}`,
  "color: green;font-weight: bold;",
  "color: red;font-weight: bold;",
);

console.log(
  `%cREACT: %c${React.version}`,
  "color: white;font-weight: bold;",
  "color: gray;font-weight: bold;",
);

interface AppProps {
  children: React.ReactNode;
}

const App = (props: AppProps) => {
  const { children } = props;

  usePlayerLoader();

  return (
    <Provider store={appStore} context={ReactReduxContext}>
      <AnalyticsProvider>
        <ScreenOrientationProvider>{children}</ScreenOrientationProvider>
      </AnalyticsProvider>
    </Provider>
  );
};

const Root = () => <RootScreen history={appHistory} />;

const AppRoot = withSentry({
  App,
  Root,
});

const rootElement = document.getElementById("root") as HTMLElement;

const root = createRoot(rootElement);
root.render(<AppRoot />);
