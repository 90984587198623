/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaModel, MEDIA_TYPE_SEASON, MediaType } from "@bms/common";
import React, { memo, useMemo } from "react";
import { Trans } from "react-i18next";

import "./MediaEpisodeInfo.scss";

export interface MediaEpisodeInfo {
  media?: IMediaModel;
}

export const MediaEpisodeInfo = memo(({ media }: MediaEpisodeInfo) => {
  const episodeInfo = useMemo(() => {
    if (media?.OrderInParent) {
      if (media?.ParentOrderInParent) {
        return (
          <Trans
            i18nKey="LIST__SEASON_EPISODE_NUMBER"
            defaults="S{{seasonNumber}}:E{{episodeNumber}}"
            values={{
              seasonNumber: media.ParentOrderInParent,
              episodeNumber: media.OrderInParent,
            }}
          />
        );
      } else if (media.MediaTypeCode === MEDIA_TYPE_SEASON) {
        return (
          <Trans
            i18nKey="ASSET_TYPE_SEASON"
            defaults="Season"
            values={{
              episodeNumber: media.OrderInParent,
            }}
          />
        );
      } else if (media.MediaTypeCode === MediaType.Podcast) {
        return (
          <Trans
            i18nKey="LIST__PODCAST_NUMBER"
            defaults="Podcast {{episodeNumber}}"
            values={{
              episodeNumber: media.OrderInParent,
            }}
          />
        );
      } else {
        return (
          <Trans
            i18nKey="LIST__EPISODE_NUMBER"
            defaults="Episode {{episodeNumber}}"
            values={{
              episodeNumber: media.OrderInParent,
            }}
          />
        );
      }
    }
  }, [media]);

  if (!media || !episodeInfo) {
    return null;
  }

  return <div className="MediaEpisodeInfo Uppercase">{episodeInfo}</div>;
});
