/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const SIGN_IN_VIA_CODE = "SIGN_IN_VIA_CODE";
export const SIGN_IN_VIA_CODE_SUCCESS = "SIGN_IN_VIA_CODE_SUCCESS";
export const SIGN_IN_VIA_CODE_FAILURE = "SIGN_IN_VIA_CODE_FAILURE";
export const SIGN_IN_ANONYMOUS = "SIGN_IN_ANONYMOUS";
export const SIGN_IN_ANONYMOUS_SUCCESS = "SIGN_IN_ANONYMOUS_SUCCESS";
export const SIGN_IN_ANONYMOUS_FAILURE = "SIGN_IN_ANONYMOUS_FAILURE";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";
export const REGISTER_EMAIL = "REGISTER_EMAIL";
export const REGISTER_EMAIL_SUCCESS = "REGISTER_EMAIL_SUCCESS";
export const REGISTER_EMAIL_FAILURE = "REGISTER_EMAIL_FAILURE";
export const REGISTER_CONFIRM_EMAIL = "REGISTER_CONFIRM_EMAIL";
export const REGISTER_CONFIRM_EMAIL_SUCCESS = "REGISTER_CONFIRM_EMAIL_SUCCESS";
export const REGISTER_CONFIRM_EMAIL_FAILURE = "REGISTER_CONFIRM_EMAIL_FAILURE";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_LINK = "RESET_PASSWORD_LINK";
export const RESET_PASSWORD_LINK_SUCCESS = "RESET_PASSWORD_LINK_SUCCESS";
export const RESET_PASSWORD_LINK_FAILURE = "RESET_PASSWORD_LINK_FAILURE";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const VALIDATE_TOKEN_SUCCESS = "VALIDATE_TOKEN_SUCCESS";
export const VALIDATE_TOKEN_FAILURE = "VALIDATE_TOKEN_FAILURE";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const REFRESH_USER = "REFRESH_USER";
export const SYNC_USER = "SYNC_USER";
export const SYNC_USER_SUCCESS = "SYNC_USER_SUCCESS";
export const SYNC_USER_FAILURE = "SYNC_USER_FAILURE";
export const CONFIRM_ACCOUNT_WITH_PASSWORD = "CONFIRM_ACCOUNT_WITH_PASSWORD";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const RESEND_CONFIRMATION_EMAIL_BY_USER =
  "RESEND_CONFIRMATION_EMAIL_BY_USER";
export const RESEND_CONFIRMATION_EMAIL_BY_USER_SUCCESS =
  "RESEND_CONFIRMATION_EMAIL_BY_USER_SUCCESS";
export const RESEND_CONFIRMATION_EMAIL_BY_USER_FAILURE =
  "RESEND_CONFIRMATION_EMAIL_BY_USER_FAILURE";
