/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AudioStore,
  DataProvider,
  MediaStreamType,
  ROUTES,
  useConfigurationSelector,
  useDispatch,
  useMedia,
  useMediaProgress,
  useSelector,
} from "@bms/common";
import React, {
  memo,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { AudioContext, BaseAudioPlayer } from "context";

import { Portal } from "../Portal";

import { ContinueListeningDialog } from "./ContinueListeningDialog";
import { WrappedReactPlayer } from "./WrappedReactPlayer";

export const AudioPlayer = memo(() => {
  const playerRef = useRef<BaseAudioPlayer | null>(null);
  const audioProgressIntervalRef = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );

  const {
    media,
    mediaPlayInfo,
    streamType,
    seekTo,
    setPlayer,
    coverSrc,
    handlers,
    playing,
    isFromAutoPlay,
  } = useContext(AudioContext);
  const src = useSelector(AudioStore.Selectors.audioSourceSelector);
  const configuration = useConfigurationSelector();
  const dispatch = useDispatch();

  const { progressInSeconds } = useMediaProgress(media);
  const isTrialContent = streamType === MediaStreamType.Trial;

  const lastTime = useMemo(() => {
    if (!!mediaPlayInfo?.Timestamp) {
      const hours = mediaPlayInfo?.Timestamp?.Hour || 0;
      const minutes = mediaPlayInfo?.Timestamp?.Minute || 0;
      const seconds = mediaPlayInfo?.Timestamp?.Second || 0;
      return hours * 60 * 60 + minutes * 60 + seconds;
    }
    return progressInSeconds;
  }, [mediaPlayInfo?.Timestamp, progressInSeconds]);
  const [openResumeModal, setOpenResumeModal] = useState(false);

  const { media: nextMedia } = useMedia({
    mediaId: mediaPlayInfo?.NextMediaId,
    disableAutoFetch: !Boolean(mediaPlayInfo?.NextMediaId),
  });

  const loadNextMedia = useCallback(() => {
    nextMedia &&
      dispatch(
        AudioStore.Actions.setAudioMedia({
          media: nextMedia,
          isFromAutoPlay: true,
        }),
      );
  }, [dispatch, nextMedia]);

  const isPlayerScreen = useMemo(() => {
    return location.pathname.includes(ROUTES.PLAYER_SCREEN);
  }, [location.pathname]);

  const setWatchProgress = useCallback(async () => {
    const currentTime = playerRef.current?.getCurrentTime();

    if (mediaPlayInfo?.MediaId && currentTime != undefined && !isTrialContent) {
      DataProvider.setProgress(mediaPlayInfo?.MediaId, currentTime);
    }
  }, [mediaPlayInfo, playerRef, isTrialContent]);

  const stopWatchProgressMonitoring = useCallback(() => {
    if (audioProgressIntervalRef.current) {
      clearInterval(audioProgressIntervalRef.current);
    }
  }, [audioProgressIntervalRef]);

  const startWatchProgressMonitoring = useCallback(() => {
    if (isTrialContent) {
      return;
    }

    stopWatchProgressMonitoring();

    audioProgressIntervalRef.current = setInterval(
      setWatchProgress,
      configuration?.System?.Media?.ProgressSyncInterval ?? 60000,
    );
  }, [
    stopWatchProgressMonitoring,
    audioProgressIntervalRef,
    setWatchProgress,
    isTrialContent,
    configuration,
  ]);

  const onResume = () => {
    handlers.onPlay();
    !isTrialContent && seekTo?.(lastTime);
    setOpenResumeModal(false);
  };

  useEffect(() => {
    setOpenResumeModal(!!mediaPlayInfo && !isTrialContent && lastTime > 0);
  }, [mediaPlayInfo, isTrialContent]);

  const onReady = (player: BaseAudioPlayer) => {
    setPlayer?.(player);
    const shouldAutoPlay =
      (isFromAutoPlay || isTrialContent) && !!mediaPlayInfo && !openResumeModal;

    if (!shouldAutoPlay) {
      return;
    }

    handlers.onPlay();
  };

  useEffect(() => {
    if (playing) {
      setWatchProgress();
      startWatchProgressMonitoring();
    } else {
      stopWatchProgressMonitoring();
    }
  }, [
    playing,
    startWatchProgressMonitoring,
    stopWatchProgressMonitoring,
    setWatchProgress,
  ]);

  const onPlay = () => {
    const { onPlay } = handlers;
    onPlay?.();
  };

  const onPause = () => {
    const { onPause } = handlers;
    // This conditional serves to avoid "DATABASE_TRANSACTION_ISOLATION_VIOLATION" error from API when 2 exactly the same timestamps are sent at the same time.
    // React Player registers onPause event event on video end and sends a timestamp here.
    // It might be a good idea to guard against this type of error on the BE if possible.
    const currentTime = playerRef.current?.getCurrentTime();
    const duration = playerRef.current?.getDuration();
    const hasEnded = currentTime && currentTime === duration;

    if (!hasEnded) {
      setWatchProgress();
    }
    onPause?.();
  };

  const onEnded = () => {
    const { onEnded } = handlers;
    onEnded?.();
    loadNextMedia();
  };

  const onCancelResume = () => {
    seekTo?.(0);
    setOpenResumeModal(false);
    handlers.onPlay();
  };

  useEffect(() => {
    if (window.location.href.indexOf("buy") > -1) {
      dispatch(AudioStore.Actions.resetStore());
    }
  }, [window.location.href]);

  if (!src || isPlayerScreen) {
    return null;
  }

  return (
    <>
      {openResumeModal && (
        <ContinueListeningDialog
          onCancel={onCancelResume}
          onConfirm={onResume}
          imageSrc={coverSrc}
        />
      )}
      <Portal portalRootId="audio-player-portal">
        <Suspense fallback={null}>
          <WrappedReactPlayer
            mediaPlayInfo={mediaPlayInfo}
            playerRef={playerRef}
            setWatchProgress={setWatchProgress}
            stopWatchProgressMonitoring={stopWatchProgressMonitoring}
            url={src}
            progressInterval={100}
            onReady={onReady}
            onPlay={onPlay}
            onPause={onPause}
            onDuration={handlers["onDuration"]}
            onEnded={onEnded}
            onProgress={handlers["onProgress"]}
            preload="auto"
            playing={playing}
            onBuffer={() => {
              console.log("Buffering...");
            }}
          />
        </Suspense>
      </Portal>
    </>
  );
});
